export default {
  state: {
    main: {
      eq: {
        center: 5,
        width: 15,
        height: 15,
        counts: 11,
        colors: ['blue', 'yellow']
      },
      eqContainer: {
        x: 0,
        y: 0
      },
      isMouseEnable: false,
      isUserCasePopup: false,
      isUserCasePopupMuted: true,
      isShowSoundController: false,
      isShowVideoReplay: false,
      isReviewerPopup: false,
      pageMaxHeight: 900
    },
    sub: {
      introduction: {
        pageMaxHeight: 500,
        youtubeURL: 'https://www.youtube-nocookie.com/embed/iYz4mtrab7g'
      },
      whenIsUseful: {
        pageMaxHeight: 3200,
        sectionMaxHeight: 1000
      },
      review: {
        defaultPos: {
          bottom: 100,
          right: 80
        },
        pageMaxHeight: 1200,
        showMessages: [false, false, false, false, false, false]
      },
      reviewMobile: {
        pageMaxHeight: 700
      },
      features: {
        pageMaxHeight: 3550,
        chapterMaxHeight: 680,
        features: [
          {
            id: 1,
            filename: 'vid_body_feature1'
          },
          {
            id: 2,
            filename: 'vid_body_feature2'
          },
          {
            id: 3,
            filename: 'vid_body_feature3'
          },
          {
            id: 4,
            filename: 'vid_body_feature4'
          },
          {
            id: 5,
            filename: 'vid_body_feature5'
          }
        ]
      },
      security: {
        pageMaxHeight: 940
      },
      dev: {
        pageMaxHeight: 400
      },
      biz: {
        pageMaxHeight: 740,
        partners: ['shinhan_bank', 'shinhan_card', 'hyundai_capital', 'ibk', 'jeju_bank', 'lguplus', 'fire_seoul', 'fire_gwangju', 'channeltalk', 'mww', 'chunmyung', 'tomorrownet', 'elice', 'table_manager']
      }
    }
  },
  getters: {
    getPageMaxHeight: (state) => state.main.pageMaxHeight,
    getSubIntroPageMaxHeight: (state) => state.sub.introduction.pageMaxHeight,
    getSubIntroYoutubeURL: (state) => state.sub.introduction.youtubeURL,
    getSubWhenIsUsefulMaxHeight: (state) => state.sub.whenIsUseful.pageMaxHeight,
    getSubWhenIsUsefulSectionMaxHeight: (state) => state.sub.whenIsUseful.sectionMaxHeight,
    getSubReviewMaxHeight: (state) => state.sub.review.pageMaxHeight,
    getSubReviewMobileMaxHeight: (state) => state.sub.reviewMobile.pageMaxHeight,
    getSubReviewMessages: (state) => state.sub.review.showMessages,
    getSubReviewDefaultPos: (state) => state.sub.review.defaultPos,
    getSubFeaturesChapterMaxHeight: (state) => state.sub.features,
    getSubFeaturesVideo: (state) => state.sub.features.features,
    getSubSecurityMaxHeight: (state) => state.sub.security.pageMaxHeight,
    getSubDevMaxHeight: (state) => state.sub.dev.pageMaxHeight,
    getEqProps: (state) => state.main.eq,
    getMouseStatus: (state) => state.main.isMouseEnable,
    getIsUserCasePopup: (state) => state.main.isUserCasePopup,
    getIsUserCasePopupMuted: (state) => state.main.isUserCasePopupMuted,
    getIsReviewerPopup: (state) => state.main.isReviewerPopup,
    getEqContainer: (state) => state.main.eqContainer,
    getDevMaxHeight: (state) => state.sub.dev.pageMaxHeight,
    getBizMaxHeight: (state) => state.sub.biz.pageMaxHeight,
    getBizPartners: (state) => state.sub.biz.partners,
    getShowVideoReplay: (state) => state.main.isShowVideoReplay,
    getShowSoundController: (state) => state.main.isShowSoundController
  },
  mutations: {
    setSubReviewMessage (state, payload) {
      state.sub.review.showMessages[payload] = true
    },
    setUserCaseSound (state, payload) {
      state.main.isUserCasePopupMuted = payload
    },
    restoreSubReviewMessage (state) {
      state.sub.review.showMessages = Array.from({ length: 6 }).fill(false)
    },
    setMouseEnableOnUserCases (state, payload) {
      state.main.isMouseEnable = payload
    },
    setReviewerPopup (state, payload) {
      state.main.isReviewerPopup = payload
    },
    setIsOpenUserCasePopup (state, payload) {
      state.main.isUserCasePopup = payload
    },
    setEqContainerOffset (state, payload) {
      state.main.eqContainer.x = payload.left
      state.main.eqContainer.y = payload.top
    },
    setShowVideoReplay (state, payload) {
      state.main.isShowVideoReplay = payload
    },
    setShowSoundController (state, payload) {
      state.main.isShowSoundController = payload
    },
    setSubReviewPopupDefaultPos (state, payload) {
      state.sub.review.defaultPos = payload
    }
  }
}

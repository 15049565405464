export default {
  state: {
    lang: '',
    event: '',
    changePlatform: false,
    platform: '',
    pageWidth: 0,
    pageMinWidth: 320,
    pageMaxWidth: 1280,
    webURL: 'https://app.vito.ai/login',
    devURL: 'https://rtzr.ai/stt',
    rtzrURL: 'https://www.rtzr.ai',
    serviceURL: 'https://vito.ai/terms/service',
    privacyURL: 'https://vito.ai/terms/privacy',
    recruitsURL: 'https://www.rtzr.ai/recruit',
    mailTo: 'contact@rtzr.ai',
    bizMailTo: 'biz@rtzr.ai'
  },
  getters: {
    getLang: (state) => state.lang,
    getEvent: (state) => state.event,
    getChangePlatform: (state) => state.changePlatform,
    getWebURL: (state) => state.webURL,
    getDevURL: (state) => state.devURL,
    getCompanyURL: (state) => state.rtzrURL,
    getRecruitsURL: (state) => state.recruitsURL,
    getServiceURL: (state) => state.serviceURL,
    getPrivacyURL: (state) => state.privacyURL,
    getInfoEmail: (state) => state.mailTo,
    getBizEmail: (state) => state.bizMailTo,
    getPageWidth: (state) => state.pageWidth,
    getPageMinWidth: (state) => state.pageMinWidth,
    getPageMaxWidth: (state) => state.pageMaxWidth,
    getUserPlatform: (state) => state.platform
  },
  mutations: {
    setLang: (state, lang) => {
      state.lang = lang
    },
    setPageWidth: (state, payload) => {
      state.pageWidth = payload
    },
    setPlatform: (state, platform) => {
      state.platform = platform
    },
    setChangePlatform: (state, payload) => {
      state.changePlatform = payload
    },
    setEvent: (state, event) => {
      state.event = event
    }
  }
}
